<template>
  <main id="Contact">
    <div class="row">
      <header>
        <p>Contact</p>
        <h2>聯絡我們</h2>
      </header>
      <div class="content">
        有任何問題歡迎透過以下管道與我們聯絡，營業時間：週一～週五 10:00~18:00
        <div class="contact_box">
          <a target="_blank" href="https://line.me/R/ti/p/%40334xpyda">LINE@</a>
          <a target="_blank" href="https://www.facebook.com/MYiiCard"
            >粉絲專頁</a
          >
          <a target="_blank" href="tel://04-2471-9799">電話客服</a>
        </div>
        或填寫以下表單，我們將有專員與您聯絡：
      </div>
      <div class="form">
        <div class="input_group">
          <label>
            姓名
            <input v-model="form_data.name" type="text" name="name" />
          </label>
          <p :class="{ active: GetError('name') != '' }" class="error">
            {{ GetError('name') }}
          </p>
        </div>
        <div class="input_group">
          <label>
            電話
            <input v-model="form_data.phone" type="text" name="phone" />
          </label>
          <p :class="{ active: GetError('phone') != '' }" class="error">
            {{ GetError('phone') }}
          </p>
        </div>
        <div class="input_group">
          <label>
            Email
            <input v-model="form_data.email" type="text" name="email" />
          </label>
          <p :class="{ active: GetError('email') != '' }" class="error">
            {{ GetError('email') }}
          </p>
        </div>
        <div class="input_group">
          <label>
            訊息內容
            <textarea v-model="form_data.comment"></textarea>
          </label>
          <p :class="{ active: GetError('comment') != '' }" class="error">
            {{ GetError('comment') }}
          </p>
        </div>
      </div>
      <div class="article_footer">
        <button @click="ValidityForm">送出訊息</button>
      </div>
    </div>
    <Footer />
  </main>
</template>

<script>
import qs from 'qs';
import axios_plugin from '@/common/axios_plugin.js';
import SmoothScrollResister from '@/common/GSAP/SmoothScrollResister.js';
import Footer from '@/components/MainFooter';
import { GetMetaData } from '@/common/meta';
export default {
  name: 'Contact',
  mixins: [SmoothScrollResister, axios_plugin],
  components: {
    Footer,
  },
  data() {
    return {
      form_data: {
        name: '',
        phone: '',
        email: '',
        comment: '',
      },
      errors: [],
      meta_data: null,
    };
  },
  methods: {
    ValidityForm() {
      this.errors = [];
      if (this.form_data.name == '' || this.form_data.name.length <= 0) {
        this.errors.push({
          type: 'name',
          content: '姓名欄位不得空白',
        });
      }
      if (this.form_data.phone == '' || this.form_data.phone.length != 10) {
        this.errors.push({
          type: 'phone',
          content: '請輸入正確的電話號碼',
        });
      }
      if (this.form_data.comment == '' || this.form_data.comment.length <= 0) {
        this.errors.push({
          type: 'comment',
          content: '請輸入訊息內容',
        });
      }

      if (this.errors.length <= 0) {
        this.SendMail();
      }
    },
    async SendMail() {
      let result = await this.SendPostData(
        `${process.env.VUE_APP_BASE_API}/Email/SendContact.php`,
        qs.stringify({ user_data: this.form_data })
      );
      if (result != 'error') {
        this.$store.commit('SetDialog', {
          action: true,
          title: '訊息已送出',
          content: '我們將會盡快回覆您的訊息！',
        });
      }
    },
    GetError(key) {
      let error = this.errors.filter((item) => item.type == key);
      return error.length > 0 ? error[0].content : '';
    },
  },
  mounted() {
    this.RegisterGsap('Contact');
    window.prerenderReady = true;
  },
  created() {
    this.meta_data = GetMetaData('聯絡我們', '', '');
  },
  computed: {
    dialog() {
      return this.$store.state.dialog.status;
    },
  },
  watch: {
    dialog() {
      this.dialog ? this.ModalToggle(true) : this.ModalToggle(false);
    },
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
